import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"


const PrivateRoute = ({ component: Component, authenticated, location, ...rest }) => {

    
    if (!authenticated.uid && location.pathname !== `/app/`) {
        // If we’re not logged in, redirect to the home page.
        navigate(`/app2/signin`)
        return null
    }

  return <Component {...rest} />
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export default PrivateRoute