import React, {useState, useEffect, useRef} from "react";
import { Formik, Form, Field } from "formik";
import NotificationSystem from 'react-notification-system';
import { StyledWrapper } from "./AccountSettings.styles";
import { Layout } from "../../components/Layout/Layout";
import firebase from 'gatsby-plugin-firebase';
import loading from '../../components/images/loading.gif';



export const AccountSettings = () => {
  const [user, setUser] = useState(null);

  const [userData, setUserData] = useState(null);

  const [accError, setAccError] = useState(null);

  const [buttonState, setButtonState] = useState(false);

  const notificationSystem = useRef();

  const addNotification = () => {

    const notification = notificationSystem.current;

    notification.addNotification({
      title: 'Account Updated',
      message: 'Your account information has been updated',
      level: 'success'
    });
  };

    useEffect(() => {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          setUser(user);
          let docRef = firebase
            .firestore()
            .collection('users')
            .doc(user.uid);

          docRef
            .get()
            .then(function (doc) {
              if (doc.exists) {
                setUserData(doc.data());
                setButtonState(false);
          
              } else {
                // doc.data() will be undefined in this case
                console.log('No such document!');
              }
            })
            .catch(function(error) {
              console.log('Error getting document:', error);
            });
          console.log(user);
        }
      });
    }, []);
  
  const validateEmail = value => {
    let error;

    if (!value) {
      error = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(value)) {
      error = 'Invalid email address';
    }
    return error;

  };

  const validateUrl = value => {
    let error;

    if (value) { 

    if (
      // eslint-disable-next-line no-useless-escape
      !/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
        value
      )
    ) {
      error = 'Invalid Url';
    }
      
  }
    return error;
    
  };

  const validateName = value => {
    let error;

    if (!value) {
      error = 'Name is required';
    } else if (value.length < 4) {
      error = 'Name should be atleast 4 characters';
    }
    return error;

  };

  return (
    <Layout>
      <NotificationSystem ref={notificationSystem} />
      <StyledWrapper>
        <div style={{ margin: '20px 0px' }}>
          <h1>Account Settings</h1>
        </div>
        <div className="box">
          {userData && (
            <Formik
              initialValues={{
                name: userData.name,
                email: userData.email,
                website: userData.website ? userData.website : '',
                role: userData.role ? userData.role : '',
                github: userData.github ? userData.github : '',
                dribble: userData.dribble ? userData.dribble : '',
              }}
              
              onSubmit={values => {
                setButtonState(true);
                console.log(values);
                firebase
                  .firestore()
                  .collection('users')
                  .doc(user.uid)
                  .set({
                    email: values.email,
                    name: values.name,
                    website: values.website,
                    role: values.role,
                    github: values.github,
                    dribble: values.dribble,
                  }, { merge: true })
                  .catch(error => {
                    // Handle Errors here.
                    let errorMessage = error.message;

                    setAccError(errorMessage);
                    // ...
                  })
                  .then(() => {
                		addNotification();
                    setButtonState(false);
                    console.log('saved');
                  });
              }}
            >
              {({
                errors,
                touched,
                handleChange,
                handleBlur,
                /* and other goodies */
              }) => (
                <Form className="form">
                  <div className="form-field">
                    <Field
                      type="text"
                      name="name"
                      className="form-input"
                      placeholder="Full Name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      validate={validateName}
                    />
                  </div>
                  <div className="red-text">{errors.name && touched.name && errors.name}</div>
                  <div className="form-field">
                    <Field
                      type="email"
                      name="email"
                      className="form-input"
                      placeholder="Email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      validate={validateEmail}
                    />
                  </div>
                  <div className="red-text">{errors.email && touched.email && errors.email}</div>
                  <div className="form-field">
                    <Field
                      type="url"
                      name="website"
                      className="form-input"
                      placeholder="Website"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      validate={validateUrl}
                    />
                  </div>
                  <div className="red-text">
                    {errors.website && touched.website && errors.website}
                  </div>
                  <div className="form-field">
                    <Field
                      type="text"
                      name="role"
                      className="form-input"
                      placeholder="Role"
                    />
                  </div>
                  <div className="form-field">
                    <Field
                      type="url"
                      name="github"
                      className="form-input"
                      placeholder="Github Url"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      validate={validateUrl}
                    />
                  </div>
                  <div className="red-text">{errors.github && touched.github && errors.github}</div>
                  <div className="form-field">
                    <Field
                      type="url"
                      name="dribble"
                      className="form-input"
                      placeholder="Dribble Url"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      validate={validateUrl}
                    />
                  </div>
                  <div className="red-text">
                    {errors.dribble && touched.dribble && errors.dribble}
                  </div>
                  <div className="form-submit">
                      {buttonState && (
                        <img src={loading} alt="loading gif" style={{ margin: 'auto' }} />
                      )}
                      {!buttonState && <input type="submit" disabled={buttonState} value="Save" />}

                  </div>
                  <div className="red-text">{accError ? <p>{accError} </p> : null}</div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </StyledWrapper>
    </Layout>
  );
};
