import React, { Fragment, useState, useRef } from "react";
import firebase from 'gatsby-plugin-firebase';
import NotificationSystem from 'react-notification-system';
import { StyledWrapper } from "./ConfirmEmail.styles";
import logo from '../../components/images/logo.png';


export const ConfirmEmail = () => {

  const [authError, setAuthError] = useState(null);

  // const userVerified = useSelector(state => state.auth.userVerified);


  const notificationSystem = useRef();

  const addNotification = () => {

    const notification = notificationSystem.current;

    notification.addNotification({
      title: 'Email Sent',
      message: 'Please check you mailbox to confirm your email',
      level: 'success'
    });
  };
  
  const sendConfirm = () => {  
    let user = firebase.auth().currentUser;

    let actionCodeSettings = {
      url: 'https://www.devwares.com/app2/signin?email=' + firebase.auth().currentUser.email,
    };

    user
      .sendEmailVerification(actionCodeSettings)
      .then(function() {
        // Email sent.
        addNotification();
      })
      .catch((error) => {
        // An error happened.
         let errorMessage = error.message;

         setAuthError(errorMessage);
      });
    
  };
  
  
  //  if (userVerified) {
  //    return <Redirect to="/dashboard" />;
  //  }
  return (
    <Fragment>
      <NotificationSystem ref={notificationSystem} />
      <StyledWrapper>
        <div className="box">
          <div className="logo">
            <img src={logo} alt="devwares" />
          </div>
          <div className="form login  center-block text-center">
            <h2> Confirm Email </h2>
            <p>Please Check your email to Verify your account</p>
            <hr className="my-2" />
            <span className="span">Didn't receive email?</span>
            <div className="form-submit">
              <input
                type="submit"
                value="Resend Email"
                onClick={() => {
                  sendConfirm();
                }}
              />
            </div>
            <div className="red-text">{authError ? <p>{authError} </p> : null}</div>
          </div>
        </div>
      </StyledWrapper>
    </Fragment>
  );
};
