import React, { useState, useEffect } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { Link } from 'gatsby';
import theme from "../../components/images/theme.png";

import { StyledWrapper, ThemeCard } from './Download.styles';
import { Layout } from '../../components/Layout/Layout';

export const Download = props => {

  const [freeRes, setFreeRes] = useState([]);

  const [premiumRes, setPremiumRes] = useState([]);

  const [downloads, setDownloads] = useState([]);
    
  const setActiveTab = (e) => {
    const a = document.querySelectorAll(".plan");
    a.forEach(item => {
      (e.target.id == item.id)
      ?  item.classList.add("active")
      :  item.classList.remove("active");
    })
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        
        let docRef =  await firebase.firestore().collection('resources');

         await docRef
          .get()
          .then(function(collection) {
            collection.forEach(doc => {
              if (!doc.data().free) {
                setPremiumRes(premiumRes => [...premiumRes, doc.data()]);
              } else {
                let docData = doc.data();
                setFreeRes(freeRes => [...freeRes, docData]);
              }
            });
          })
          .catch(function(error) {
            console.log('Error getting document:', error);
          });

        let docRef2 = firebase
          .firestore()
          .collection('users')
          .doc(user.uid);

        docRef2
          .get()
          .then(function(doc) {
            if (doc.exists) {
              let userData = doc.data();

              userData.downloads.forEach(download => {
                download.resource
                  .get()
                  .then(res => {
                    setDownloads( downloads => [...downloads, res.data()]);
                  })
                  .catch(err => console.error(err));
              });
            } else {
              // doc.data() will be undefined in this case
              console.log('No such document!');
            }
          })
          .catch(function(error) {
            console.log('Error getting document:', error);
          });
      }
    });
  }, []);

  return (
    <Layout>
      <StyledWrapper>
        <div className="box">
          <h2>Downloads</h2>
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a className="nav-link plan active" id="free" onClick={setActiveTab} data-toggle="tab" href="#free">
                Free
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link plan" id="premium" onClick={setActiveTab} data-toggle="tab" href="#premium">
                Premium
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div className="row plan active" id="free">
              {freeRes.length
                ?
                  freeRes.map((download, index) => (
                    <div key={index} className="col-sm-12 col-md-6 col-lg-6 col-xl-6 column">
                      <ThemeCard>
                        <Link to={download.infoLink}>
                        <div className="theme-image">
                          <img src={download.imageUrl} style={{maxHeight: "250px"}} alt="Theme" />
                          </div>
                        </Link>
                        <div className="theme-body">
                          <div className="theme-caption">
                            <h3>{download.name}</h3>

                            <p>{download.desc}</p>
                          </div>
                        
                        </div>
                        <div className="theme-footer">
                          <div className="theme-badges">
                            <span>{download.free ? "Free" : "Pro"}</span>
                          </div>
                          <a href={download.npmLink || download.downloadUrl}><h3>Download</h3></a>
                        </div>
                      </ThemeCard>
                    </div>
                  ))
                : (<h5 className="mx-auto">You do not have any free downloads yet</h5>)
              }
            </div>
            <div className="row plan" id="premium">
              {downloads.length
                ?
                downloads.map((download, index) => (
                    <div key={index} className="col-sm-12 col-md-6 col-lg-6 col-xl-6 column">
                    <ThemeCard>
                      <Link to={download.infoLink}>
                        <div className="theme-image">
                          <img src={download.imageUrl} alt="Theme" />
                        </div>
                      </Link>
                      <div className="theme-body">
                        <div className="theme-caption">
                          <h3>{download.name}</h3>

                          <p>{download.desc}</p>
                        </div>

                      </div>
                      <div className="theme-footer">
                        <div className="theme-badges">
                          <span>{download.free ? "Free" : "Pro"}</span>
                        </div>
                        <a href={download.npmLink || download.downloadUrl}><h3>Download</h3></a>
                      </div>
                    </ThemeCard>
                    </div>
                  ))
                : (<h5 className="mx-auto">You do not have any Premium downloads yet</h5>)
              }
            </div>
          </div>
        </div>
      </StyledWrapper>
    </Layout>
  );
};
