import React, { useState, useEffect, Fragment } from 'react';
import { Router } from '@reach/router';
import firebase from 'gatsby-plugin-firebase';
import { ChangePassword } from '../../containers/ChangePassword/ChangePassword';
import { ConfirmEmail } from '../../containers/ConfirmEmail/ConfirmEmail';
import { AccountSettings } from '../../containers/AccountSettings/AccountSettings';
import { Download } from '../../containers/Download/Download';
import PrivateRoute from '../../components/PrivateRoute';

const App2 = () => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setUser(user);
      } else {
        setUser('logged out');
      }
    });
  }, []);
  return (
    
    <Router>
      {user && (
        <Fragment>
        <PrivateRoute
          path="/app/account-settings"
          authenticated={user}
          component={AccountSettings}
        />
        <PrivateRoute
          path="/app/change-password"
          authenticated={user}
          component={ChangePassword}
        />
        <PrivateRoute path="/app/downloads" authenticated={user} component={Download} />
        <PrivateRoute path="/app/confirm" authenticated={user} component={ConfirmEmail} />
        </Fragment>
      )
      }
      </Router>

  );
};

export default App2;