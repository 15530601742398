import styled from "styled-components";

export const StyledWrapper = styled.section`
  display: flex;
  justify-content: center;
  margin-bottom: 50px !important;
  .box {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    margin: 0 auto;
    width: 95%;
    h2 {
      font-size: 40px;
      text-align: center;
      font-weight: 500;
      padding-bottom: 20px;
    }
    @media (max-width: 768px) {
      ul {
        margin: 30px auto;
        .nav-item {
          font-size: 20px !important;
          margin: 0 auto !important;
          padding: 0 15px !important;
          &:hover {
            border: none !important;
          }
          &:focus {
            border: none !important;
          }
        }
      }
    }
    ul {
      margin: 50px auto;
      list-style: none;
      text-decoration: none;
      display: flex;
      flex-direction: row;
      justify-content: space-space-between;
      .nav-item {
        margin: 0 30px;
        font-size: 30px;
        padding: 0 20px;
        color: #d0c9d6;
        text-transform: uppercase;
        cursor: pointer;
        .nav-link {
          color: #b4b4b4;
        }
        .nav-link.active {
          color: #333;
          border-top: none;
          border-left: none;
          border-right: none;
        }
      }
      .active {
        color: #333333 !important;
        border-bottom: 3px solid #6979f8 !important;
      }
    }
    .row {
      margin: 20px 0;
      display: none;
      .column {
        padding: 20px;
      }
      @media (max-width:767px) {
        .column{
          padding:10px;
        }
      }
    }
    .row.active {
      display: flex;
    }
  }
`;
export const ThemeCard = styled.div`
  padding: 10px;
  width: 100%;
  height: 100%;
  display:flex;
  margin:0 auto;
  max-width:450px;
  border-radius: 5px;
  flex-direction:column;
  // border: 0.5px solid #b4b4b4;
  border: 0.5px solid #ddd;
  // box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  .theme-image {
    border-radius: 5px;
    height:200px;
    overflow:hidden;
    img {
      width: 100%;
      height: auto;
      border-radius: 5px;
      object-fit: cover;
    }
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  }
  .theme-body {
    display: flex;
    position: relative;
    flex-direction: row;
    // padding-bottom: 25px;
    .theme-caption {
      padding: 15px 0;

      h3 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 20px;
        font-weight: 550;
        padding-right: 0px !important;
        padding-bottom: 0px !important;
      }
      p {
        display: flex;
        justify-content: flex-start;
        font-size: 14px;
      }
    }
    .theme-button {
      display:none;
      color: #3329e0;
      padding: 8px 16px;
      border-radius: 3px;
      background: #ccc9ff; 
      align-items: center;
      justify-content: flex-start;
    }
  }
  .theme-footer {
    display: flex;
    flex-direction: row;
    margin-top:auto;
    align-items:center;
    .theme-badges {
      span {
        color:#5a55e4;
        margin: 0 auto;
        margin-right: 15px !important;
        padding: 3.5px 10px;
        font-size: 12px;
        background: #ffe5d3;
        border-radius: 6px;
      }
    }
    a {
      margin-left: auto;
    }
    h3 {
      margin:0;
      font-size: 16px;
      color: #4339f2;
      color: #3329e0;
      padding: 8px 16px;
      border-radius: 3px;
      background: #ccc9ff;
    }
  }
`;
