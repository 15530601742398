import React, { Fragment, useState, useRef } from "react";
import { Formik, Form, Field } from "formik";
import firebase from 'gatsby-plugin-firebase';
import NotificationSystem from 'react-notification-system';
import { StyledWrapper } from "./ChangePassword.styles";
import { Layout } from '../../components/Layout/Layout';
import loading from '../../components/images/loading.gif';


export const ChangePassword = () => {
  const [password, setPassword] = useState("");

  const [authError, setAuthError] = useState(null);

  const [buttonState, setButtonState] = useState(false);

  const notificationSystem = useRef();

  const addNotification = () => {

    const notification = notificationSystem.current;

    notification.addNotification({
      title: 'Password Changed',
      message: 'Your password has been updated',
      level: 'success'
    });
  };

  const validatePassword = (value) => {
    let error;

    setPassword(value);
    if (!value) {
      error = "Passoword is required";
    } else if (value.length < 8) {
      error = "Passoword should be atleast 8 characters";
    }
    return error;
  };

  const validateConfirmPassword = (value) => {
    let error;

    if (!value) {
      error = "Confirm Passoword is required";
    } else if (value.length < 8) {
      error = "Confirm Passoword should be atleast 8 characters";
    } else if (value !== password) {
      error = "Password does not match";
    }
    return error;
  };

  const newPassword = values => {
    let user = firebase.auth().currentUser;

    user
      .updatePassword(values.password)
      .then(function() {
        // Update successful.
        setButtonState(false);
        addNotification();
      })
      .catch(error => {
         let errorMessage = error.message;

        // An error happened.
        setAuthError(errorMessage);
        
      });  

    };
  
  return (
    <Layout>
      <Fragment>
        <NotificationSystem ref={notificationSystem} />
        <StyledWrapper>
          <div style={{ margin: '20px 0px' }}>
            <h1>Change Password</h1>
          </div>
          <div className="box">
            <Formik
              initialValues={{
                password: '',
                confirmPassword: '',
              }}
              onSubmit={values => {
                setButtonState(true);
                newPassword(values);
              }}
            >
              {({
                errors,
                touched,
                handleChange,
                handleBlur,
                /* and other goodies */
              }) => (
                <Form className="form login">
                  <div className="form-field">
                    <Field
                      type="password"
                      name="password"
                      className="form-input"
                      placeholder="New Password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      validate={validatePassword}
                    />
                  </div>
                  <div className="red-text">
                    {errors.password && touched.password && errors.password}
                  </div>
                  <div className="form-field">
                    <Field
                      type="password"
                      name="confirmPassword"
                      className="form-input"
                      placeholder="Confirm Password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      validate={validateConfirmPassword}
                    />
                  </div>
                  <div className="red-text">
                    {errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}
                  </div>
                  <div className="red-text">{authError ? <p>{authError} </p> : null}</div>
                  <div className="form-submit">
                      {buttonState && (
                        <img src={loading} alt="loading gif" style={{ margin: 'auto' }} />
                      )}
                      {!buttonState && <input type="submit" disabled={buttonState} value="Save" />}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </StyledWrapper>
      </Fragment>
    </Layout>
  );
};
